<template>
  <div v-if="isCreateAllowed">
    <h4>{{ t.t("codes.createCampaign") }}</h4>
    <validation-observer ref="promotionValidation">
      <div class="promotion-inputs-container">
        <b-form-group
          :label="t.t('global.nameGeo')"
          label-for="nameGeo"
          class="mb-0 w-100 mr-2"
        >
          <validation-provider
            #default="{ errors }"
            name="nameGeo"
            rules="required"
          >
            <b-form-input
              v-model="nameGeo"
              :placeholder="t.t('global.nameGeo')"
              name="nameGeo"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="t.t('global.nameEng')"
          label-for="nameEng"
          class="mb-0 w-100 mr-2"
        >
          <validation-provider
            #default="{ errors }"
            name="nameEng"
            rules="required"
          >
            <b-form-input
              v-model="nameEng"
              :placeholder="t.t('global.nameEng')"
              name="nameEng"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
    <div class="">
      <b-button
        :disabled="isTableBusy"
        variant="success"
        class="mt-1 ml-auto"
        @click.prevent="handleSubmit"
      >
        {{ t.t("global.submit") }}
      </b-button>
    </div>
    <hr />
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import isAllowed from "@core/utils/isAllowed";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";

export default {
  name: "CreatePromotionForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      nameGeo: "",
      nameEng: "",
      required,
      t: null,
    };
  },
  computed: {
    ...mapState("promotionModule", ["isTableBusy"]),
    isCreateAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor"], roles);
    },
  },
  created() {
    this.t = this.$i18n;
  },
  methods: {
    ...mapActions("promotionModule", ["createPromotion", "getPromotions"]),
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    async handleSubmit() {
      const result = await this.$refs.promotionValidation.validate();
      if (result) {
        await this.createPromotion({
          nameGeo: this.nameGeo,
          nameEng: this.nameEng,
        });
        this.getPromotions({ take: 10, skip: 0 });
        this.nameGeo = "";
        this.nameEng = "";
        requestAnimationFrame(() => {
          this.$refs.promotionValidation.reset();
        });
        this.showToast("Promotion created successfully", "success");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.promotion-inputs-container {
  display: flex;
  align-items: end;
}
</style>
