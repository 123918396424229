<template>
  <b-card
    header="Campaigns"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <CreatePromotionForm />
    <PromotionsTable />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import CreatePromotionForm from "./components/CreatePromotionForm.vue";
import PromotionsTable from "./components/PromotionsTable.vue";

export default {
  name: "Promotions",
  components: {
    BCard,
    CreatePromotionForm,
    PromotionsTable,
  },
  computed: {
    ...mapState("promotionModule", ["promotionsList"]),
  },
  methods: {
    ...mapActions("promotionModule", ["getPromotions"]),
  },
};
</script>

<style></style>
