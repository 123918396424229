<template>
  <div>
    <!-- filters jere -->
    <FiltersAndSearch
      :id.sync="id"
      :searchKey.sync="searchKey"
      disableActiveOnly
      :sortOrder.sync="sortOrder"
    />
    <!-- end of filters -->
    <b-table
      sticky-header
      :items="promotionsList.campaigns"
      bordered
      :fields="fields"
      responsive
      :busy="isTableBusy"
      hover
      class="products-table shadow-sm rounded"
      show-empty
      empty-text="No matching records found"
      small
    >
      <template v-if="isEditAllowed" #cell(actions)="data">
        <!-- some day they will ask to re-enable this, and you will thank me I never deleted it -->
        <!-- <b-button variant="flat-danger" class="btn-icon mr-1" @click="deletePromotion(data.item)">
          <feather-icon size="16" icon="TrashIcon" />
        </b-button> -->
        <b-button
          variant="flat-success"
          class="btn-icon"
          @click="updatePromotionHandler(data.item)"
        >
          <feather-icon size="16" icon="SaveIcon" />
        </b-button>
      </template>
      <template #cell(nameEng)="data">
        <b-form-input v-model="data.item.nameEng" :readonly="!isEditAllowed" />
      </template>
      <template #cell(nameGeo)="data">
        <b-form-input v-model="data.item.nameGeo" :readonly="!isEditAllowed" />
      </template>
    </b-table>
    <Pagination
      :fetch-data="getAndUpdatePromotions"
      :total-rows="totalRows"
      :take.sync="take"
      :skip.sync="skip"
    />
  </div>
</template>

<script>
import { BTable, BButton, BFormInput } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import isAllowed from "@core/utils/isAllowed";
import FiltersAndSearch from "@/views/components/FiltersAndSearch/FiltersAndSearch.vue";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "PromotionsTable",
  components: {
    BTable,
    Pagination,
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    FiltersAndSearch,
  },
  data() {
    return {
      totalRows: 0,
      take: 10,
      skip: 0,
      id: "",
      searchKey: "",
      sortOrder: 2,
    };
  },
  computed: {
    ...mapState("promotionModule", ["promotionsList", "isTableBusy"]),
    isEditAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor"], roles);
    },
    fields() {
      return [
        {
          label: "id",
          key: "id",
        },
        {
          label: this.$i18n.t("global.action"),
          key: "actions",
          class: "actions-column",
        },
        {
          label: this.$i18n.t("global.nameGeo"),
          key: "nameGeo",
        },
        {
          label: this.$i18n.t("global.nameEng"),
          key: "nameEng",
        },
      ];
    },
  },
  watch: {
    take() {
      this.getAndUpdatePromotions();
    },
    searchKey() {
      this.getAndUpdatePromotions();
    },
    id() {
      this.getAndUpdatePromotions();
    },
    sortOrder() {
      this.getAndUpdatePromotions();
    },
  },
  created() {
    this.getAndUpdatePromotions();
  },
  methods: {
    ...mapActions("promotionModule", [
      "getPromotions",
      "deletePromotionById",
      "updatePromotion",
    ]),
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    async getAndUpdatePromotions() {
      try {
        await this.getPromotions({
          take: this.take,
          skip: this.skip,
          id: this.id,
          sortOrder: this.sortOrder,
          searchKey: this.searchKey,
        });
        this.totalRows = this.promotionsList.count;
      } catch (e) {
        console.log(e);
      }
    },
    async updatePromotionHandler(item) {
      const payload = {
        id: item.id,
        nameEng: item.nameEng,
        nameGeo: item.nameGeo,
      };
      try {
        await this.updatePromotion(payload);
        this.getAndUpdatePromotions();
        this.showToast("Promotion updated successfully", "success");
      } catch (error) {
        console.log(error);
      }
    },
    async deletePromotion(item) {
      // this function is not present in the view app;

      const response = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to delete this promotion ?",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (response) {
        try {
          await this.deletePromotionById(item.id);
          this.getAndUpdatePromotions();
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.actions-column {
  width: 40px;
}
</style>
